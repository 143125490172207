import { Injectable } from '@angular/core';
import { Holiday } from '../../models/collections/holidays.model';
import { CurrentUserService } from '../current-user.service';
import { DatabaseService } from '../database.service';
@Injectable({
  providedIn: 'root'
})
export class HolidaysService {

  collectionName: string = 'riHolidays';
  constructor(
    private db: DatabaseService,
  ) { }

  getAllHolidays() {
    return this.db.getCollection(this.collectionName);
  }
}
