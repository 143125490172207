import { HolidaysService } from './../../services/collections/holidays.service';
import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { NavigationService } from '../../services/navigation.service';
import { Holiday } from '../../models/collections/holidays.model';
import { DatabaseService } from '../../services/database.service';
import { I18nService } from '../../services/i18n.service';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment-timezone';
@Component({
  selector: 'fa-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.css']
})
export class HolidaysComponent implements OnInit {
  holidayName: string;
  holidayDate: Date;
  holidayDescription: string = '';

  constructor(
    private db: DatabaseService,
    private alert: AlertService,
    private i18n: I18nService,
    private navigation: NavigationService

  ) { }

  ngOnInit() {
  }
  async addHoliday(event: Event, form: HTMLFormElement) {
    try {
      form.isSubmitted = true;
      if (form.checkValidity()) {
        const target = event.target as any;
        const startLoading = () => {
          target.isDisabled = true;
          target.isLoading = true;
        };
        const stopLoading = () => {
          target.isDisabled = false;
          target.isLoading = false;
        };
        startLoading();
        // Add Holiday

        const holidayDateInUSA = moment(this.holidayDate).tz('America/New_York').format('MM/DD/YYYY');
        const holidayDate: Date = new Date(holidayDateInUSA);
        const uuid = uuidv4();
        const holiday: Holiday = {
          id: uuid,
          date: holidayDate,
          holidayName: this.holidayName,
          day: holidayDate.getDate(),
          month: holidayDate.getMonth(),
          year: holidayDate.getFullYear(),
          description: this.holidayDescription
        }

        await this.db.setDocument('riHolidays', uuid, holiday);
        this.alert.success(this.i18n.get('Holiday Added'), false, 5000, true);
        stopLoading();
        this.navigation.redirectTo('holidays', 'list');
      }
    } catch (error) {
      console.log(error);
    }

  }

}
