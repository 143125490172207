import { HolidaysService } from './../../../services/collections/holidays.service';
import { Component, OnInit } from '@angular/core';
import { Holiday } from '../../../models/collections/holidays.model';
import { AlertService } from '../../../services/alert.service';
import { I18nService } from '../../../services/i18n.service';
import { DatabaseService } from '../../../services/database.service';
@Component({
  selector: 'fa-list-holiday',
  templateUrl: './list-holiday.component.html',
  styleUrls: ['./list-holiday.component.css']
})
export class ListHolidayComponent implements OnInit {

  allHolidays: any;
  selectedHoliday: Holiday = null;
  constructor(private holidayService: HolidaysService,
    private alert: AlertService,
    private i18n: I18nService,
    private db: DatabaseService) { }

  ngOnInit() {
    this.holidayService.getAllHolidays().subscribe((res: Holiday[]) => {
      this.allHolidays = res;
      console.log(this.allHolidays)
    });
  }

  async deleteHoliday(holiday: Holiday) {
    try {
      await this.db.deleteDocument('riHolidays', holiday.id);
      this.alert.success(this.i18n.get('Holiday Deleted', { holiday: `${holiday.holidayName}` }), false, 5000);
    } catch (error) {
       console.log(error);
    }

  
     }
  }
