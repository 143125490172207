// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA5lsZuk6FgLO_qAdx5eBfx8ynhHwNm-z0",
    authDomain: "vcc-dev-firebase-qa-a44b.firebaseapp.com",
    databaseURL: "https://vcc-dev-firebase-qa-a44b.firebaseio.com",
    projectId: "vcc-dev-firebase-qa-a44b",
    storageBucket: "vcc-dev-firebase-qa-a44b.appspot.com",
    messagingSenderId: "59192836653",
    appId: "1:59192836653:web:5fa790376cda911bc3eea4"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
